import React, { lazy, Suspense } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PreLoader from "components/preLoader/PreLoader";
const Home = lazy(() => import("../pages/Home"));
const Companies = lazy(() => import("../pages/Companies"));
const PartnersHome = lazy(() => import("../pages/PartnersHome"));
const Bonds = lazy(() => import("../pages/Bonds"));
const AboutHome = lazy(() => import("../pages/AboutHome"));
const Terms = lazy(() => import("../pages/Terms"));
const Policy = lazy(() => import("../pages/Policy"));
const Security = lazy(() => import("../pages/Security"));

const Config = () => {
  React.useEffect(() => {
    AOS.init({
      // duration: 1000,
      // easing: "ease-out-cubic",
      once: true,
    });
  }, []);

  return (
    <Router>
      <Suspense fallback={<PreLoader />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/companies" element={<Companies />} />
          <Route path="/partners" element={<PartnersHome />} />
          <Route path="/bond" element={<Bonds />} />
          <Route path="/about" element={<AboutHome />} />
          <Route path="/terms-of-use" element={<Terms />} />
          <Route path="/privacy" element={<Policy />} />
          <Route path="/security" element={<Security />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default Config;
