import React from "react";
import "./App.css";
import Config from "config/Config";



const App = () => (

    <Config />

);

export default App;
